/* -----------------------------------------------------------
    Author       : Tanvirul Haque
    Template Name: Multi - Onepage Business Template
    Version      : 1.0
------------------------------------------------------------*/
/*============================================================
    CSS TABLE OF CONTENTS
==============================================================
    01. GENAREL CSS STYLE
    02. START HEADER AREA CSS STYLE
    03. START BOOTSTRAP NAVIGATION OVERRIDES CSS STYLE
    04. START ABOUT AREA CSS STYLE
    05. START SERVICES AREA CSS STYLE
    06. START SKILLS AREA CSS STYLE
    07. START PORTFOLIO AREA CSS STYLE
    08. START COUNT AREA CSS STYLE
    09. START TEAM AREA CSS STYLE
    10. START PRICING AREA CSS STYLE
    11. START TESTIMONIAL AREA CSS STYLE
    12. START BLOG AREA CSS STYLE
    13. START PRODUCTS AREA CSS STYLE
    14. START PARTNER AREA CSS STYLE
    15. START CONTACT FORM AREA CSS STYLE
    16. START FOOTER TOP AREA CSS STYLE
    17. START FOOTER AREA CSS STYLE
    18. START PROJECT PAGE CSS STYLE
=============================================================*/
/*
* -------------------------------------------------------------
*    01. GENAREL CSS STYLE
* -------------------------------------------------------------
*/
body {
    width: 100%;
    height: 100%;
    color: #555;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
}
html,
body { height: 100% }
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: #333;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Titillium Web', sans-serif;
}
a {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    -webkit-transition: all 0.36s;
    transition: all 0.36s;
}
a:hover {
    color: #fff;
    text-decoration: none;
}
a:focus {
    outline: none;
    text-decoration: none;
    color: #fff;
}
p { line-height: 2 }
ul,
li {
    margin: 0;
    padding: 0;
}
fieldset {
    border: 0 none;
    margin: 0 auto;
    padding: 0;
}
.section-padding { padding: 80px 0 }
.section-title { margin-bottom: 60px }
.section-title h2 {
    margin-bottom: 10px;
    padding-bottom: 15px;
    font-size: 36px;
    position: relative;
}
.section-title h2:after {
    border-bottom: 3px solid #333;
    bottom: 0;
    content: "";
    height: 3px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 10%;
}
.section-title p {
    margin: auto;
    width: 70%;
}
.breadcrumb-main{
    background:url("../img/bg/breadcrumb-bg.jpg");
    background-position: center center;
    background-size: cover;
    position: relative;
    height:  350px;
    z-index:1;
}
.breadcrumb-main:after {
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}
.breadcrumb-title{ padding-top: 200px }
.breadcrumb-title h2{
    font-weight: 700;
    color: #fff;
}
.breadcrumb-title p,
.breadcrumb-title p a{ color: #fff }
.button{
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 25px;
    position:relative;
    font-weight: 700;
    font-size: 13px;
    color: #333;
    letter-spacing:1px;
    background: transparent;
    border: 2px solid #333;
    -webkit-transition: 0.36s;
            transition:0.36s;
}
.button:focus { color:#333 }
.button:hover {
    color:#fff;
    background: #333;
}
.owl-carousel .item  {
    display: block;
    width: 100%;
    height: auto;
}
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #fff;
}
.loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-top-color: #3498db;
    z-index: 1001;
}
.spinner-cube-grid.spinner {
    width: 80px;
    height: 80px;
    margin: 0 auto;
}
.spinner-cube-grid .cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.spinner-cube-grid .cube:nth-child(1) {
    -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.spinner-cube-grid .cube:nth-child(2) {
    -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.spinner-cube-grid .cube:nth-child(3) {
    -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.spinner-cube-grid .cube:nth-child(4) {
    -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.spinner-cube-grid .cube:nth-child(5) {
    -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.spinner-cube-grid .cube:nth-child(6) {
    -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.spinner-cube-grid .cube:nth-child(7) {
    -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.spinner-cube-grid .cube:nth-child(8) {
    -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.spinner-cube-grid .cube:nth-child(9) {
    -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@keyframes cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
    }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
    }
}
.loaded .loader {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 1.3s ease-out;
            transition: all 1.3s ease-out;
}
.loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transition: all 0.9s ease-out;
            transition: all 0.9s ease-out;
}
/*
* -------------------------------------------------------------
*    01. END GENAREL CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    02. START HEADER AREA CSS STYLE
* -------------------------------------------------------------
*/
.header-area { height: 100%; }
.header-top-bar {
    background: #333 none repeat scroll 0 0;
    color: #fff;
    font-size: 13px;
    font-weight: 300;
    padding: 10px 0;
}
.top-social-icon a {
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    -webkit-transition: 0.36s;
    -o-transition: 0.36s;
    transition: 0.36s;
}
.top-social-icon a:hover {
    transform: scale(1.1);
}
.header-top-cart ul li a{ color: #fff }
.header-top-info i {
    margin-left: 20px;
    margin-right: 5px;
}
.shop-cart i { font-size: 16px }
.shop-cart > span {
    top: 0;
    right: 0;
    position: absolute;
    background: #fff none repeat scroll 0 0;
    border-radius: 50%;
    color: #333;
    display: inline-block;
    font-size: 10px;
    height: 18px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    width: 18px;
    margin-top: -6px;
}
.home-slide-area,
.home-slide-area div { height: 100% }
.home-slide-area div.home-display-table-cell,
.home-slide-area div.home-display-table-cell div { height: auto }
.single-slide-bg {
    position: relative;
    z-index: 1;
}
.single-slide-bg:after {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
}
.home-display-table {
    width: 100%;
    height: 100%;
    display: table;
}
.home-display-table-cell {
    display: table-cell;
    vertical-align: middle;
}
.slide-bg1 {
    background: url("../img/bg/slide1.jpg") scroll 0 0;
    background-size: cover;
}
.slide-bg2 {
    background: url("../img/bg/slide2.jpg") scroll 0 0;
    background-size: cover;
}
.slide-bg3 {
    background: url("../img/bg/slide3.jpg") scroll 0 0;
    background-size: cover;
}
#home-carousel .item p {
    color: #fff;
    font-size: 18px;
    letter-spacing: 7px;
}
#home-carousel .item h2 {
    width: 70%;
    color: #fff;
    font-size: 60px;
    font-weight: 300;
    margin: 0 auto 30px;
}
#home-carousel .item h2 span { font-weight: 900; }
#home-carousel .item .button { color: #fff }
#home-carousel .carousel-indicators > li { border-radius: 0; }
.carousel-fade .carousel-inner .item {
    -webkit-transition-property: opacity;
            transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.home-btn-bg {
    background: #333;
    margin-right: 10px;
}
/*
* -------------------------------------------------------------
*    02. END HEADER AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    03. START BOOTSTRAP NAVIGATION OVERRIDES CSS STYLE
* -------------------------------------------------------------
*/
.header-top-area {
    position: relative;
    background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
    width: 100%;
    z-index: 999;
    -webkit-transition: all 0.36s ease-out;
            transition: all 0.36s ease-out;
}
.sticky-wrapper {
    position: absolute;
    width: 100%;
    z-index: 999;
}
.navbar-default {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    border-radius: 0;
    margin-bottom: 0;
    -webkit-transition: all 0.36s ease-in-out 0s;
    transition: all 0.36s ease-in-out 0s;
    width: 100%;
}
.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus { background-color: transparent }
.navbar-brand {
    color: #fff !important;
    font-family: 'Titillium Web', sans-serif;
    font-size: 28px;
    font-weight: 700;
    padding: 30px 0;
    -webkit-transition: 0.36s;
    transition: 0.36s;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form { border-color: #fff }
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus { background-color: #fff }
.navbar-default .navbar-toggle .icon-bar { background-color: #333 }
.mainmenu li a {
    color: #fff !important;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    padding: 30px 15px;
    -webkit-transition: all 0.36s ease-in-out 0s;
    transition: all 0.36s ease-in-out 0s;
}
.mainmenu ul li:last-child a { padding-right: 0 }
.mainmenu ul.nav.navbar-nav li a:hover { color: #333 !important }
.navbar-default .navbar-toggle { background: #fff none repeat scroll 0 0 }
.is-sticky .header-top-area{
    background: #fff;
    box-shadow: 0px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
}
.is-sticky .mainmenu li a {
    padding: 20px 15px;
    color: #666 !important;
}
.is-sticky .mainmenu ul.nav.navbar-nav li.active a { color: #333 !important }
.is-sticky .navbar-brand {
    color: #333 !important;
    font-size: 26px;
    padding: 20px 0;
}
/*
* -------------------------------------------------------------
*    03. END BOOTSTRAP NAVIGATION OVERRIDES CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    04. START ABOUT AREA CSS STYLE
* -------------------------------------------------------------
*/
.about-text h3,
.skills-progress h3 { margin-bottom: 20px }
.about-text p { margin-bottom: 15px }
.about-text ul { margin-bottom: 25px }
.about-text ul li {
    font-size: 13px;
    margin-bottom: 10px;
}
.about-text li i {
    margin-right: 5px;
    color: #333;
}
.video-area iframe {
    width: 100%;
    height: 310px;
    border: medium none;
}
/*
* -------------------------------------------------------------
*    04. / END ABOUT AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    05. START SERVICES AREA CSS STYLE
* -------------------------------------------------------------
*/
.service-area {
    background: #f6f6f6 none repeat scroll 0 0;
    padding-bottom: 50px;
}
.single-service {
    background: #fff;
    margin-bottom: 30px;
    padding: 50px 25px;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}

.single-service:hover { box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08) }
.single-service i {
    font-size: 52px;
    color: #333;
}
.single-service h4 {
    margin: 20px 0 10px 0;
    font-size: 20px;
}
.single-service p { font-size: 13px }
a.read-more i {
    font-size: 10px;
    margin-right: 5px;
    color: #999;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}
a.read-more {
    color: #999;
    font-size: 12px;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}

a.read-more:hover i,
a.read-more:hover { color:#333 }
/*
* -------------------------------------------------------------
*    05. / END SERVICES AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    06. START SKILLS AREA CSS STYLE
* -------------------------------------------------------------
*/
.single-skill h3 { margin-top: 15px }
.progressbar {
  position: relative;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  overflow: hidden;
}
.progressbar div {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.progressbar div span {
  position: absolute;
  font-size: 25px;
  line-height: 175px;
  height: 175px;
  width: 175px;
  left: 12.5px;
  top: 12.5px;
  border-radius: 50%;
  background-color: white;
}
.progressbar .rotate {
  clip: rect(0 100px 200px 0);
}
.progressbar .left {
  clip: rect(0 100px 200px 0);
  opacity: 1;
}
.progressbar .right {
  clip: rect(0 100px 200px 0);
  transform: rotate(180deg);
  opacity: 0;
}
 @keyframes
toggle {  0% {
     opacity: 0;
    }
     100% {
     opacity: 1;
    }
}
/*
* -------------------------------------------------------------
*    06. / END SKILLS AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    07. START PORTFOLIO AREA CSS STYLE
* -------------------------------------------------------------
*/
.portfolio-area { background: #f6f6f6 none repeat scroll 0 0 }
.single-work {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}
.single-work:hover { box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08) }
.work-overlay {
    background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0;
    position: absolute;
    padding: 20px;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 0.6s ease-in-out 0s;
            transition: all 0.6s ease-in-out 0s;
}
.single-work:hover .work-overlay {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.work-title > h4 { margin-top: 60px }
.work-title p{ margin: 0 }
.work-icon a {
    color: #666;
    display: inline-block;
    font-size: 18px;
    margin: 5px;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}
.work-icon a:hover{ color: #333 !important }
.portfolio-btn a i {
    font-size: 14px;
    margin-right: 10px;
}
/*
* -------------------------------------------------------------
*    07. / END PORTFOLIO AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    08. START COUNT AREA CSS STYLE
* -------------------------------------------------------------
*/
.count-area {
    background: url("../img/bg/count-bg.jpg");
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.count-area:after {
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    left: 0;
    top: 0;
    z-index: -111;
}
.counter {
    background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
    padding: 30px;
    color: #fff;
}
.counter h2 {
    margin: 10px 0 5px 0;
    font-size: 34px;
    color: #fff;
}
.counter p {
    margin: 0;
}
.counter i {
    font-size: 40px;
}
/*
* -------------------------------------------------------------
*    08. / END COUNT AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    09. START TEAM AREA CSS STYLE
* -------------------------------------------------------------
*/
.single-team{
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}
.single-team:hover { box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08) }
.team-overlay {
    background: #f6f6f6;
    padding: 20px;
}
.team-title { float: left }
.team-title p{
    margin: 0;
    font-size: 12px;
}
.team-social-link {
    float: right;
    margin-top: 10px;
}
.team-social-link a i {
    border: 1px solid #555;
    display: inline-block;
    text-align: center;
    line-height: 25px;
    font-size: 12px;
    height: 25px;
    width: 25px;
    color: #555;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}
.team-social-link a i:hover {
    background: #333;
    border: 1px solid #555;
    color: #fff;
}
/*
* -------------------------------------------------------------
*    09. / END TEAM AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    10. START PRICING AREA CSS STYLE
* -------------------------------------------------------------
*/
.pricing-area { background: #f6f6f6 }
.single-pricing {
    padding: 20px 0;
    background: #fff;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.single-pricing:hover { box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08) }
.price {
    padding: 25px;
    -webkit-transition: all 0.6s ease 0s;
            transition: all 0.6s ease 0s;
}
.single-pricing h3 {
    border-bottom: 1px solid #ddd;
    display: inline-block;
    font-family: "Ubuntu",sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
    margin-bottom: 10px;
    padding: 0 0 10px;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.price h4 {
    color: #666;
    font-size: 52px;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.price span {
    font-size: 14px;
    display: block;
    margin-top: 10px;
}
.pricing-list {
    list-style: outside none none;
    text-align: center;
    padding: 0 25px;
}
.pricing-list li {
    border-bottom: 1px solid #eee;
    color: #161616;
    padding: 15px 0;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.pricing-btn { padding: 25px 0 }
.single-pricing.recommended {
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.single-pricing.recommended .price h4,
.single-pricing:hover .price h4 { color: #333 }
/*
* -------------------------------------------------------------
*    10. / END PRICING AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    11. START TESTIMONIAL AREA CSS STYLE
* -------------------------------------------------------------
*/
.testimonial-area {
    background: url("../img/bg/testimonial-bg.jpg");
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.testimonial-area:after {
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    left: 0;
    top: 0;
    z-index: -111;
}
.testimonial-area .item img {
    background: rgba(255, 255, 255, 0.3) none repeat scroll 0 0;
    margin: 0 auto 10px;
    height: 70px;
    padding: 5px;
    width: auto;
}
.cutomers-rating {  margin-bottom: 10px }
.cutomers-rating i { color: #ffc207 }
.testimonial-area .item h6 {
    font-weight: 400;
    margin: 10px 0 20px;
    color: #fff;
}
.testimonial-area .item h4,
.testimonial-area .item p { color: #fff }
#testimonial-carousel .carousel-indicators-slider {
  bottom: -40px;
}
#testimonial-carousel .carousel-indicators > li {
  border-radius: 0;
}
/*
* -------------------------------------------------------------
*    11. / END TESTIMONIAL AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    12. START BLOG AREA CSS STYLE
* -------------------------------------------------------------
*/
.blog-area { background: #f6f6f6 }
.single-blog {
    background: #fff;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.single-blog:hover { box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08) }
.single-blog img { padding: 10px 10px 0 10px }
.blog-content { padding: 25px }
.blog-content span {
    font-size: 12px;
    color: #333;
}
.blog-content span i { color: #333 }
.blog-content a h2 {
    font-size: 20px;
    margin: 5px 0 15px;
}
.blog-btn { margin-top: 30px }
.blog-btn a i {
    font-size: 14px;
    margin-right: 10px;
}
/*
* -------------------------------------------------------------
*    12. / END BLOG AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    13. START PRODUCTS AREA CSS STYLE
* -------------------------------------------------------------
*/
.shop-grid .shop-all{
    display: none;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.shop-filter ul {
    list-style: outside none none;
    margin-bottom: 10px;
    text-align: center;
}
.shop-filter ul li {
    margin: 5px;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 20px;
    display: inline-block;
    border: 2px solid #f6f6f6;
    text-transform: uppercase;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}
.shop-filter ul li.active, .shop-filter ul li:hover {
    background: #333 none repeat scroll 0 0;
    border: 2px solid #333;
    color: #fff;
}
.single-product {
    background: #f6f6f6;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.single-product:hover {
    background: transparent;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.08);
}
.product-img { position: relative }
.product-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    -webkit-transition: all 0.36s ease-in-out 0s;
            transition: all 0.36s ease-in-out 0s;
}
.single-product:hover .product-overlay{
    background: rgba(255,255,255, 0.7);
    opacity: 1;
}
.product-overlay > a.button { margin-top: 43% }
.product-info { padding: 20px }
.single-product img { padding: 10px 10px 0 10px }
.product-info h4 { margin: 5px 0 }
.product-rating li { padding: 0 }
.product-rating i { color: #ffc321 }
.product-info p { margin: 0 }
.shop-btn { margin-top: 30px }
.shop-btn a i {
    font-size: 14px;
    margin-right: 10px;
}
/*
* -------------------------------------------------------------
*    13. / END PRODUCTS AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    14. START PARTNER AREA CSS STYLE
* -------------------------------------------------------------
*/
.partner-area { background: #f6f6f6 }
#partner-carousel .item img {
    display: block;
    margin: 0 auto;
    width: auto;
}
.partner-carousel-table {
    display: table;
    height: 150px;
    width: 100%;
}
.partner-carousel-tablecell {
    display: table-cell;
    vertical-align: middle;
}

/*
* -------------------------------------------------------------
*    14. / END PARTNER AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    15. START CONTACT FORM AREA CSS STYLE
* -------------------------------------------------------------
*/
.form-control {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #999;
    border-radius: 0;
    box-shadow: none;
    height: 45px;
    padding: 10px;
    transition: all 0.36s ease 0s;
}
.form-control::-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.form-control:hover,
.form-control:focus {
    border-color: #333;
    box-shadow: none;
}
.success {
    text-align: center;
    margin: 80px 0;
    font-size: 18px;
    font-weight: 700;
}
/*
* -------------------------------------------------------------
*    15. / END CONTACT FORM AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    16. START FOOTER TOP AREA CSS STYLE
* -------------------------------------------------------------
*/
.footer-top-area { background: #f6f6f6 none repeat scroll 0 0 }
.footer-widget h4 { margin-bottom: 20px }
.single-contact-info { margin-bottom: 15px }
.single-contact-info i {
    background: #333;
    text-align: center;
    margin-right: 10px;
    line-height: 40px;
    font-size: 16px;
    height: 40px;
    color: #fff;
    float: left;
    width: 40px;
}
.single-contact-info p { font-size: 12px }
.single-latest-post {
    margin-bottom: 10px;
    overflow: hidden;
}
.single-latest-post img {
    background: #f0f0f0 none repeat scroll 0 0;
    float: left;
    margin-right: 10px;
    padding: 3px;
    width: 30%;
}
.single-latest-post a h2 {
    font-size: 12px;
    margin: 5px 0;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.single-latest-post span { font-size: 12px }
.single-latest-post span i {
    margin-right: 5px;
    font-size: 14px;
    color: #333;
}
.single-latest-post:hover a h2{ color: #333 }
.footer-widget ul li a {
    color: #666;
    display: block;
    margin-bottom: 10px;
    -webkit-transition: all 0.36s ease 0s;
            transition: all 0.36s ease 0s;
}
.footer-widget ul li a i {
    color: #333;
    margin-right: 5px;
}
.footer-widget ul li a:hover { color: #333 }
/*
* -------------------------------------------------------------
*    16. / END FOOTER TOP AREA CSS STYLE
* -------------------------------------------------------------
*/
/*
* -------------------------------------------------------------
*    17. START FOOTER AREA CSS STYLE
* -------------------------------------------------------------
*/
.copyright {
    background: #333;
    padding: 30px 0;
    color: #fff;
}
.copyright p { margin: 0 }
/*
* -------------------------------------------------------------
*    17. / END FOOTER AREA CSS STYLE
* -------------------------------------------------------------
*/

/*
* -------------------------------------------------------------
*    18. SINGLE PROJECT PAGE CSS STYLE
* -------------------------------------------------------------
*/
.details-single-project{ margin: 40px 0 20px }
.about-single-project h3,
.details-single-project h3 {
    margin-bottom: 20px;
}
.details-single-project li {
    color: #555;
    font-size: 14px;
    padding: 0 0 10px 0;
}
.details-single-project li a,
.details-single-project li i {
    margin-right: 10px;
    color: #333;
}
.related-projects {
    margin-top: 50px;
}
.related-projects .single-work{
    margin-bottom: 0;
}
.related-projects h3 {
    margin-bottom: 30px;
    text-align: left;
}
/*
* -------------------------------------------------------------
*    18. END PROJECT PAGE CSS STYLE
* -------------------------------------------------------------
*/
